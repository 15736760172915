import { useNotifications } from "../../utils/NotificationContext";
import ScrollContent from "../../components/ScrollContent";

import "./styles.css";
import TopMenu from "../../components/TopMenu";
import { getLegalInformation } from "../../utils/API/interaction";
import { useEffect, useState } from "react";
import { LoadingTitle } from "../../components/PageTitle";
import useCustomNavigate from "../../utils/CustomNavigate";
const AboutPage = () => {
  const { addNotification } = useNotifications();
  const navigator = useCustomNavigate();
  const [aboutInformation, setAboutInformation] = useState(null);
  const [appVersion, setAppVersion] = useState("");

  useEffect(() => {
    (async () => {
      let newAboutInformation = await getLegalInformation(["ABOUT"]);

      if (newAboutInformation.code === 200) {
        setAboutInformation(newAboutInformation.data);
        return;
      } else {
        addNotification(newAboutInformation.message);
        navigator("/home");
      }
    })();

    (async () => {
      try {
        const response = await fetch("/manifest.json");
        const manifest = await response.json();
        setAppVersion(manifest.version);
      } catch (error) {
        console.error("Error fetching manifest.json:", error);
        setAppVersion("Unknown");
      }
    })();
  }, []);

  if (!aboutInformation)
    return (
      <>
        <TopMenu backLink="settings" showMenu={false} /> <LoadingTitle />
      </>
    );
  return (
    <>
      <TopMenu backLink="settings" showMenu={false} />
      <ScrollContent className="absolute about-wrapper">
        {aboutInformation.map((about, index) => (
          <div key={about.key}>
            <h2>{about.title}</h2>
            {about.text.map((p, index) => (
              <p key={index} dangerouslySetInnerHTML={{ __html: p }}></p>
            ))}
          </div>
        ))}

        <div className="app-version">
          <p>App Version: {appVersion}</p>
        </div>
      </ScrollContent>
    </>
  );
};

export default AboutPage;
